import { useState, lazy, useEffect, useRef } from "react";
import Grain from "./components/Grain";
import Header from "./components/Header";
import Nav from "./components/Nav";
import Menu from "./components/Menu";
import styles from './styles/Desktop.module.css'
import Background from "./components/Background";
import PixelTransition from "./components/PixelTransition";
import { AnimatePresence, easeOut, motion } from "framer-motion";
import About from "./components/About";
import Docs from "./components/Docs";
import ASCIIPhotos from "./components/ASCIIPhotos";
import Challenge from "./components/Challenge";
import Roots from "./components/Roots";
import Team from "./components/Team";
import Programs from "./components/Programs";
import LearnMore from "./components/LearnMore";
import { ParallaxProvider } from "react-scroll-parallax";

const Home = lazy(() => import('./components/Home'));
const Intro = lazy(() => import('./components/Intro'));

const storyboardVariants = {
  initial: { opacity: 0, y: -10 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.3, ease: easeOut } },
  exit: { opacity: 0, y: 10, transition: { duration: 0.3, ease: easeOut } }
};

const asciiPhotoVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.2, ease: easeOut } },
  exit: { opacity: 0, transition: { duration: 0.4, ease: easeOut } }
};

interface aboutProps {
  height: number
  hasDocsEntered: boolean
}


interface docsProps {
  hasDocsEntered: boolean
}

const docsDelay = 1.4

const aboutVariants = {
  initial: { opacity: 0, y: -10 },
  animate: ({height, hasDocsEntered} : aboutProps) => ({ opacity: 1, y: hasDocsEntered ? (height * -0.1) : 0, transition: { duration: 0.8, ease: easeOut } }),
  exit: { opacity: 0, y: 10, transition: { duration: 0.4, ease: easeOut } }
};

const docsVariants = {
  initial: { opacity: 0, y: 30 },
  animate: ({hasDocsEntered} : docsProps) => ({ opacity: 1, y: 0, transition: { duration: 0.7, ease: easeOut, delay: hasDocsEntered ? 0 : docsDelay } }),
  exit: { opacity: 0, y: 30, transition: { duration: 0.8, ease: easeOut } }
};

export default function Desktop() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showASCIIPhotos, setShowASCIIPhotos] = useState(false);
  const [height, setHeight] = useState(window.innerHeight)
  const [storyboardIndex, setStoryboardIndex] = useState(0)
  const [prevStoryboardIndex, setPrevStoryboardIndex] = useState(-1)
  const [hasDocsEntered, setHasDocsEntered] = useState(false)

  /* Scrollable interactions */
  const scrollRef = useRef<HTMLDivElement>(null)

  /* Home */
  const homeRef = useRef(null);
  /* About & History */
  const introRef = useRef(null);
  const aboutRef = useRef(null);
  const challengeRef = useRef(null);
  const visionRef = useRef(null);
  const rootsRef = useRef(null);
  /* Team & Fellows */
  const teamRef = useRef(null);
  /* Programs */
  const programsRef = useRef(null);
  const learnMoreRef = useRef(null);

  const sectionRefs = [homeRef, introRef, aboutRef, challengeRef, visionRef, rootsRef, teamRef, programsRef, learnMoreRef]

  useEffect(() => {
    if (storyboardIndex === 6) {
      if (scrollRef.current) {
        scrollRef.current.style.overflowY = 'hidden'
        scrollRef.current.style.pointerEvents = 'none'
      }
    } else {
      if (scrollRef.current) {
        scrollRef.current.style.overflowY = ''
        scrollRef.current.style.pointerEvents = 'all'
      }
    }
  }, [storyboardIndex])

  const handleScroll = (e : React.UIEvent<HTMLDivElement>) => {
    const scrollPos = e.currentTarget.scrollTop;

    // Listen for halfway point (scroll-snap brings us to the next section)
    // Otherwise, artificial delay appears
    const computedIndex = Math.floor((scrollPos + (height / 2)) / height)

    if (storyboardIndex != computedIndex) { // Only set if changed
      if (storyboardIndex === 2 && computedIndex === 1) {
        setHasDocsEntered(false)
        setPrevStoryboardIndex(storyboardIndex)
        setStoryboardIndex(computedIndex)
      }
      if (storyboardIndex === 1 && computedIndex === 2) {
        setPrevStoryboardIndex(storyboardIndex)
        setStoryboardIndex(computedIndex)
        setTimeout(() => {
          setHasDocsEntered(true)
        }, 1200)
      } else if (storyboardIndex === 2 && computedIndex === 3) {
        setShowASCIIPhotos(true)

        // Temporarily disable scrolling during ASCII photo animation
        e.currentTarget.style.overflow = 'hidden';
        setTimeout(() => {
          setShowASCIIPhotos(false)
          setPrevStoryboardIndex(storyboardIndex)
          setStoryboardIndex(computedIndex)

          setTimeout(() => { // Re-enable scrolling
            if (scrollRef.current) {
              scrollRef.current.style.overflow = '';
            }
          }, 600)
        }, 1300)
      } else if (computedIndex === 6) {
        if (scrollRef.current) {
          scrollRef.current.style.overflowY = 'hidden'
          scrollRef.current.style.pointerEvents = 'none'
        }
        setPrevStoryboardIndex(storyboardIndex)
        setStoryboardIndex(computedIndex)
      } else {
        setPrevStoryboardIndex(storyboardIndex)
        setStoryboardIndex(computedIndex)
      }
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <AnimatePresence>
      <motion.div
        key="wrapper"
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
        transition={{duration: 0.3, ease: easeOut}}
        className={styles.wrapper}
      >
      <Grain/>
      <Header setIsMenuOpen={setIsMenuOpen} />
      <Nav isMenuOpen={isMenuOpen} scrollRef={scrollRef} height={height} />
      <Background storyboardIndex={storyboardIndex} />
      <div className={styles.sections}>
      <AnimatePresence mode="wait">
        {storyboardIndex === 0 && (
          <motion.div
            key="home"
            variants={storyboardVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <Home isMobile={false} />
          </motion.div>
        )}
        {storyboardIndex === 1 && (
          <motion.div
            key="intro"
            variants={storyboardVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className={styles.introWrapper}
          >
            <Intro isMobile={false} />
          </motion.div>
        )}
        {(storyboardIndex === 2 && !showASCIIPhotos) && (
          <>
            <motion.div
              key="about"
              variants={aboutVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              custom={{ height: height, hasDocsEntered: hasDocsEntered }}
            >
              <About isMobile={false} />
            </motion.div>
          </>
        )}
        {showASCIIPhotos && (
          <motion.div
            key="gif"
            variants={asciiPhotoVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <ASCIIPhotos />
          </motion.div>
        )}
        {(storyboardIndex === 3 || storyboardIndex === 4) && (
          <motion.div
            key="challenge"
            variants={storyboardVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className={styles.capturePointerEvents}
          >
            <Challenge showVision={storyboardIndex === 4} playInitialTransition={prevStoryboardIndex === 2 && storyboardIndex === 3} />
          </motion.div>
        )}
        {(storyboardIndex === 5) && (
          <motion.div
            key="roots"
            variants={storyboardVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className={styles.scrollableChild}
          >
            <Roots parentScrollRef={scrollRef} isMobile={false} />
          </motion.div>
        )}
        {(storyboardIndex === 6) && (
          <motion.div
            key="team"
            variants={storyboardVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className={styles.scrollableChild}
          >
            <Team parentScrollRef={scrollRef} isMobile={false} />
          </motion.div>
        )}
        {(storyboardIndex === 7) && (
          <motion.div
            key="programs"
            variants={storyboardVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className={styles.scrollableChild}
          >
            <Programs parentScrollRef={scrollRef} isMobile={false} />
          </motion.div>
        )}
        {(storyboardIndex === 8) && (
          <motion.div
            key="learn-more"
            variants={storyboardVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className={styles.scrollableChild}
          >
            <LearnMore isMobile={false} />
          </motion.div>
        )}
      </AnimatePresence>
      </div>
      <div ref={scrollRef} className={styles.scrollableContainer} onScroll={handleScroll}>
        {sectionRefs.map((ref, i) => 
        <div key={i} ref={ref} className={styles.scrollable}></div>
        )}
      </div>
      {(storyboardIndex === 2 && !showASCIIPhotos) && (
        <motion.div
          className={styles.docsContainer}
          key="docs"
          variants={docsVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          custom={{ hasDocsEntered: hasDocsEntered }}
        >
          <Docs isMobile={false} />
        </motion.div>
        )}
      <Menu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} scrollRef={scrollRef} height={height} isMobile={false} />
      <PixelTransition isMenuOpen={isMenuOpen}/>
      </motion.div>
    </AnimatePresence>
  );
}
