import { useEffect, useRef } from 'react'
import styles from '../styles/Team.module.css'
import { motion, AnimatePresence, easeOut, easeInOut } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { fadeVariants } from '../utils/constants'

interface TeamProps {
  parentScrollRef: React.RefObject<HTMLDivElement> | null
  isMobile: boolean
}

export default function Team({ parentScrollRef, isMobile } : TeamProps) {
  const scrollRef = useRef(null)
  const scrollIntoRef = useRef<HTMLDivElement | null>(null)
  const { ref: topRef, inView: topInView, entry: topEntry } = useInView({
    threshold: 0,
  });
  const { ref: bottomRef, inView: bottomInView, entry: bottomEntry } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (scrollIntoRef.current) {
      scrollIntoRef.current.scrollIntoView({block: "start"})
    }
  }, [])

  const handleScroll = (e: React.WheelEvent<HTMLDivElement>) => {
    if (parentScrollRef && parentScrollRef.current) {
      parentScrollRef.current.style.pointerEvents = 'all'
      parentScrollRef.current.style.overflowY = 'scroll'
      if (topInView) {
        parentScrollRef.current.scrollTo({
          left: 0,
          top: 5 * window.innerHeight,
          behavior: 'instant'
        })
      }
      else if (bottomInView) {
        parentScrollRef.current.scrollTo({
          left: 0,
          top: 7 * window.innerHeight,
          behavior: 'instant'
        })
      }
    }
  };

  const textVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 0.4, ease: easeOut } },
    exit: { opacity: 0, transition: { duration: 0.4, ease: easeOut } }
  };

  return (
    <AnimatePresence>
    <motion.div
      className={isMobile ? styles.mobileContainer : styles.scrollableContainer}
      ref={scrollRef}
      onWheel={isMobile ? () => {} : handleScroll}
      variants={fadeVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {!isMobile && <div ref={topRef} className={styles.top}></div>}
      {!isMobile && <div ref={scrollIntoRef} className={styles.scrollInto}></div>}
      <div className={styles.gradient}></div>
      <div className={styles.introContainer}>
        <div className={styles.introInnerBorder}>
          <motion.p
            className={styles.subheading}
            variants={textVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            key="intro-1"
          >At BiTS, we are led by <span className={styles.em}>experts with a deep understanding</span> of the science and technology landscape.
          </motion.p>
          <motion.p
            className={styles.body}
            variants={textVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            key="intro-2"
          >Our leadership team has years of experience managing multi-million-dollar research programs at institutions like DARPA and ARPA-H, and they are committed to helping the next generation of science leaders succeed.
          </motion.p>
        </div>
      </div>
      <motion.div
        className={styles.connector}
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: 70, opacity: 1, transition: { delay: 0.2, duration: 0.4, ease: easeOut } }}
        exit={{ height: 0, opacity: 0 }}
        key="intro-renphil"
      />
      <div className={styles.renphilContainer}>
        <div className={styles.introInnerBorder}>
          <motion.p
            className={styles.subheading}
            variants={textVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            key="renphil"
          >The Big if True Science (BiTS) Accelerator is an initiative of <span className={styles.em}>Renaissance Philanthropy</span> to empower visionary scientists to design and lead large-scale, coordinated research programs that <span className={styles.em}>unlock transformative technologies for humanity's future.</span>
          </motion.p>
        </div>
      </div>
      <motion.div
        className={styles.connector}
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: 70, opacity: 1, transition: { delay: 0.2, duration: 0.4, ease: easeOut } }}
        exit={{ height: 0, opacity: 0 }}
        key="renphil-advisors"
      />
      <motion.div
        className={styles.advisorsContainer}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.3, duration: 1, ease: easeInOut } }}
        exit={{ opacity: 0 }}
        key="advisors"
      >
        <motion.div
          className={styles.advisorsInnerBorder}
          variants={textVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          key="advisors-inner-border"
        >
          <motion.h3 className={styles.heading}>Our Advisors</motion.h3>
          <div className={styles.divider}></div>
          <div className={styles.advisors}>
            <motion.div className={styles.advisor}>
              <img className={styles.headshot} src='../images/tom.png'/>
              <p className={styles.name}>Tom Kalil, CEO</p>
              <div className={styles.divider}></div>
              <p className={styles.body}>Renaissance Philanthropy</p>
            </motion.div>
            <motion.div className={styles.advisor}>
              <img className={styles.headshot} src='../images/brad.png'/>
              <p className={styles.name}>Brad Ringeisen</p>
              <div className={styles.divider}></div>
              <p className={styles.body}>ED, innovative genomics at Berkeley</p>
            </motion.div>
            <motion.div className={styles.advisor}>
              <img className={styles.headshot} src='../images/pat.png'/>
              <p className={styles.name}>Pat McGrath</p>
              <div className={styles.divider}></div>
              <p className={styles.body}>Pat McGrath, Program Director, Schmidt Sciences (former ARPA-E)</p>
            </motion.div>
            <motion.div className={styles.advisor}>
              <img className={styles.headshot} src='../images/hemai.png'/>
              <p className={styles.name}>Hemai Parthasarathy</p>
              <div className={styles.divider}></div>
              <p className={styles.body}>Former Head of Rapid Evaluation, Google X (general tech, science, neuroscience)</p>
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
      <motion.div
        className={styles.connector}
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: 70, opacity: 1, transition: { delay: 0.4, duration: 0.4, ease: easeOut } }}
        exit={{ height: 0, opacity: 0 }}
        key="advisors-team"
      />
      <motion.div
        className={styles.teamContainer}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.6, duration: 1, ease: 'easeInOut' } }}
        exit={{ opacity: 0 }}
        key="team"
      >
        <motion.div
          className={styles.teamInnerBorder}
          variants={textVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          key="team-inner-border"
        >
          <motion.h3 className={styles.heading}>Our Team</motion.h3>
          <div className={styles.divider}></div>
          <div className={styles.team}>
            <motion.div className={styles.member}>
              <img className={styles.headshot} src='../images/joshua.png'/>
              <div className={styles.info}>
                <p className={styles.name}>Joshua Elliott</p>
                <div className={styles.divider}></div>
                <p className={styles.body}>With over six years as a DARPA Program Manager, Joshua oversaw programs with a combined budget of almost $600M. His vision for BiTS is to empower scientists to turn their “big, if true” ideas into actionable, well-funded programs.</p>
              </div>
            </motion.div>
            <div className={styles.divider}></div>
            <motion.div className={styles.member}>
              <img className={styles.headshot} src='../images/eric.png'/>
              <div className={styles.info}>
                <p className={styles.name}>Eric Gilliam</p>
                <div className={styles.divider}></div>
                <p className={styles.body}>Eric has spent several years producing operational histories of historically-great industrial R&D labs, science philanthropies, and DARPA projects which he publishes here. He uses this work to collaborate with those in the R&D ecosystem on their own projects. Before this, he worked with Freakonomics author Steve Levitt at the University of Chicago Center for RISC. </p>
              </div>
            </motion.div>
            <div className={styles.divider}></div>
            <motion.div className={styles.member}>
              <img className={styles.headshot} src='../images/emma.png'/>
              <div className={styles.info}>
                <p className={styles.name}>Emma Cappiello</p>
                <div className={styles.divider}></div>
                <p className={styles.body}>With 7+ years of expertise in strategy, operations, and program management, including her recent role at Schmidt Futures, Emma brings a results-driven approach to BiTS, ensuring seamless program execution. She envisions empowering every PM with the resources and support they need to accelerate their impact.</p>
              </div>
            </motion.div>
            <div className={styles.divider}></div>
            <div className={styles.siteCreds}>
              <motion.div className={styles.member}>
                <img className={styles.headshot} src='../images/aileen.png'/>
                <div className={styles.info}>
                  <p className={styles.name}>Aileen Luo</p>
                  <div className={styles.divider}></div>
                  <p className={styles.body}>She designed this ;)</p>
                </div>
              </motion.div>
              <motion.div className={styles.member}>
                <img className={styles.headshot} src='../images/chloe.png'/>
                <div className={styles.info}>
                  <p className={styles.name}>Chloe Yan</p>
                  <div className={styles.divider}></div>
                  <p className={styles.body}>She developed this ;)</p>
                </div>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </motion.div>
      {!isMobile && <div ref={bottomRef} className={styles.bottom}></div>}
    </motion.div>
    </AnimatePresence>
  )
}
