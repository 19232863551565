import React, { useEffect, useMemo, useState } from 'react';
import styles from '../styles/PixelTransition.module.css';
import { motion } from 'framer-motion';

interface PixelTransitionProps {
  isMenuOpen: boolean;
}

interface Block {
  key: string;
  custom: number[];
}

/**
 * Shuffles array in place (Fisher–Yates shuffle).
 * @param {Array} a items An array containing the items.
 */
const shuffle = (a: number[]) => {
  let j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

export default function PixelTransition({ isMenuOpen }: PixelTransitionProps) {
  const { innerWidth, innerHeight } = window;
  const blockSize = 40;
  const screenHeight = innerHeight - 64;
  const numCols = Math.ceil(innerWidth / blockSize);
  const numBlocks = Math.ceil(screenHeight / blockSize);
  const initialBlocks = [...Array(numCols)].map((_, col) => {
    const shuffledIndexes = shuffle([...Array(numBlocks)].map((_, i) => i));
    return shuffledIndexes.map((rand, i) => ({
      key: `${col}-${i}`,
      custom: [col + rand, numCols - col + rand],
    }));
  });
  const [blocks, setBlocks] = useState<Block[][]>(initialBlocks);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const blockVariants = {
    initial: {
      opacity: 0
    },
    open: (delay: number[]) => ({
      opacity: 1,
      transition: { duration: 0, delay: 0.0065 * delay[0] }
    }),
    closed: (delay: number[]) => ({
      opacity: 0,
      transition: { duration: 0, delay: 0.2 + 0.00625 * delay[1] }
    })
  }

  const handleResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    const { innerWidth, innerHeight} = window;
    const screenHeight = innerHeight - 64;
    const numCols = Math.ceil(innerWidth / blockSize);
    const numBlocks = Math.ceil(screenHeight / blockSize);

    const initial = [...Array(numCols)].map((_, col) => {
      const shuffledIndexes = shuffle([...Array(numBlocks)].map((_, i) => i));
      return shuffledIndexes.map((rand, i) => ({
        key: `${col}-${i}`,
        custom: [col + rand, numCols - col + rand],
      }));
    });
    setBlocks(initial)

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width, height])

  return (
    <div className={styles.pixelBackground}>
      {
        blocks.map((colBlocks, colIndex) => (
          <div key={colIndex} className={styles.column}>
            {colBlocks.map(({ key, custom }) => (
              <motion.div 
                key={key} 
                className={styles.block}
                variants={blockVariants}
                initial="initial"
                animate={isMenuOpen ? "open" : "closed"}
                custom={custom}
              />
            ))}
          </div>
        ))
      }
    </div>
  );
}
