import styles from '../styles/Background.module.css'
import eye from '../assets/videos/eye.mp4';
import eyeImg from '../assets/images/eye.webp';
import blueLight from '../assets/images/blue-light.png';
import Video from './Video';
import { motion, AnimatePresence, easeOut } from 'framer-motion';

interface BackgroundProps {
  storyboardIndex: number
}

const backgroundVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.8, ease: easeOut } },
  exit: { opacity: 0, transition: { duration: 0.8, ease: easeOut } }
};

export default function Background({ storyboardIndex } : BackgroundProps) {
  return (
    <AnimatePresence>
    {(storyboardIndex === 0) &&
      <motion.div
        className={styles.container}
        variants={backgroundVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        key="0"
      >
        <div className={styles.greenRectangle}></div>
        <Video vidSrc={eye} imgSrc={eyeImg} style={styles.eye} />
        <img className={styles.tree} src='../images/tree.webp' />
        <img className={styles.grassRight} src='../images/grass.webp' />
        {/* <Video vidSrc={grass} imgSrc={grassImg} style={styles.grassRight} /> */}
        <img className={styles.eyeMain} src='../images/eye-main.png' />
        <img className={styles.blueLight} src={blueLight.src} />
        <div className={styles.black}></div>
      </motion.div>
    }
    {(1 <= storyboardIndex && storyboardIndex <= 6) &&
      <motion.div
        className={styles.gradientContainer}
        variants={backgroundVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        key="3"
      >
        <img className={styles.diagonalGradient} src='../images/team-bg.svg' />
      </motion.div>
    }
    {(storyboardIndex === 7) &&
      <motion.div
        className={styles.blobContainer}
        variants={backgroundVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        key="8"
      >
        <img className={styles.blob} src='../images/programs-bg.webp' />
      </motion.div>
    }
    </AnimatePresence>
  )
}