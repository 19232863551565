import { useEffect, useRef, useState } from 'react';
import styles from '../styles/Roots.module.css'
import { motion, AnimatePresence, useScroll, useTransform } from 'framer-motion'
import { useInView } from 'react-intersection-observer';
import { Parallax, useParallax } from 'react-scroll-parallax';
import { fadeVariants } from '../utils/constants';

interface RootsProps {
  parentScrollRef: React.RefObject<HTMLDivElement> | null
  isMobile: boolean
}

export default function Roots({ parentScrollRef, isMobile } : RootsProps) {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const scrollIntoRef = useRef<HTMLDivElement | null>(null)
  const { ref: topRef, inView: topInView, entry: topEntry } = useInView({
    threshold: 0,
  });
  const { ref: bottomRef, inView: bottomInView, entry: bottomEntry } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (isMobile) return

    if (scrollIntoRef.current) {
      scrollIntoRef.current.scrollIntoView({block: "start"})
    }
  }, [scrollIntoRef])

  const handleScroll = (e: React.WheelEvent<HTMLDivElement>) => {
    if (parentScrollRef && parentScrollRef.current) {
      parentScrollRef.current.style.pointerEvents = 'all'
      parentScrollRef.current.style.overflowY = 'scroll'
      if (topInView) {
        parentScrollRef.current.scrollTo({
          left: 0,
          top: 4 * window.innerHeight,
          behavior: 'instant'
        })
      }
      else if (bottomInView) {
        parentScrollRef.current.scrollTo({
          left: 0,
          top: 6 * window.innerHeight,
          behavior: 'instant'
        })
      }
    }
  };

  return (
    <AnimatePresence>
    <motion.div
      ref={scrollRef}
      className={styles.scrollableContainer}
      onWheel={isMobile ? () => {} : handleScroll}
      variants={fadeVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {(!isMobile) && <div ref={topRef} className={styles.top}></div>}
      <div className={isMobile ? styles.mobileContainer : styles.container} >
        <div className={styles.innerBorder} >
          <img className={styles.ditherFade} src='../images/dither-fade-mini.webp' />
          <h1 className={styles.title}>Our <span className={styles.green}>Roots.</span></h1>
          <div className={styles.columnContainer}>
            <div className={styles.column}>
              <img className={styles.arrowBetween} src='../images/roots-chevron-down.svg'/>
              <div className={styles.section}>
                <p className={styles.heading}>The Team's DARPA Experience</p>
                <p className={styles.body}>Joshua Elliott, a former Program Manager at DARPA, oversaw nearly $600M in programs during his six-year tenure. His work provided key insights into how to lead high-risk, high-reward projects and set the foundation for his future initiatives.</p>
                <img className={styles.arrow} src='../images/roots-chevron-down.svg'/>
              </div>
              <img className={styles.arrowBetween} src='../images/roots-chevron-down.svg'/>
              <div className={styles.section}>
                <p className={styles.heading}>Mentoring Future Leaders (2022-2023)</p>
                <p className={styles.body}>Joshua mentored potential ARPA-H program managers through Actuate, helping them shift from traditional academic thinking to the high-risk, high-reward mindset that defines DARPA PMs. This experience demonstrated the potential to train future leaders and sparked the creation of the Breakthrough Research Accelerator for Innovative Non-profit Science (Brains Initiative).</p>
                <img className={styles.arrow} src='../images/roots-chevron-down.svg'/>
              </div>
              <img className={styles.arrowBetween} src='../images/roots-chevron-down.svg'/>
              <div className={styles.section}>
                <p className={styles.heading}>The Evolution to BiTS (2024)</p>
                <p className={styles.body}>Building on the success of the Brains Initiative, BiTS (Big if True Science) was launched. The program expands the original framework, leveraging lessons from DARPA's successes and failures, and culminated in the creation of a "Playbook for ARPA-PMs."</p>
                <img className={styles.arrow} src='../images/roots-chevron-down.svg'/>
                </div>
            </div>
            <div className={styles.column}>
              <div className={styles.section}>
                <p className={styles.heading}>Identifying a Critical Challenge (Late 2022)</p>
                <p className={styles.body}>In late 2022, after speaking with leaders from ARPA-like organizations (ARIA, NCADE, ARPA-H) and pioneers of new non-profit models (FROs), Joshua identified a significant challenge: how to source and train effective program leaders without the long-established history and momentum of DARPA.</p>
              </div>
              <img className={styles.arrowBetween} src='../images/roots-chevron-down.svg'/>
              <div className={styles.section}>
                <p className={styles.heading}>The Brains Initiative Takes Shape (2023-2024)</p>
                <p className={styles.body}>The Brains Initiative was developed to train scientists and innovators to think and act like DARPA PMs. Through pilot efforts, including a collaboration with the UK's ARIA, Joshua's team trained and mentored the first eight ARIA Programme Directors. The first open fellowship cohort of 16 fellows ended with a Demo Day attended by ARPA leaders.</p>
              </div>
              <img className={styles.arrowBetween} src='../images/roots-chevron-down.svg'/>
              <div className={styles.section}>
                <p className={styles.heading}>BiTS and Renaissance Philanthropy</p>
                <p className={styles.body}>BiTS is now a project of Renaissance Philanthropy (RenPhil), founded by Tom Kalil. It aims to fuel a 21st-century renaissance by connecting philanthropists, scientists, and innovators. The transition from Brains to BiTS reflects the program's expanded scope and focus on developing high-risk, high-reward leaders across various sectors.</p>
              </div>
            </div>            
          </div>
        </div>
        <div>
          <motion.img src='../images/rocket.webp' className={styles.rocket} drag dragConstraints={scrollRef} />
          <motion.img src='../images/darpa-tag.webp' className={styles.darpaTag} drag dragConstraints={scrollRef} />
          <motion.img src='../images/photo-muniza-drag.webp' className={styles.photoMuniza} drag dragConstraints={scrollRef} />
          <motion.img src='../images/photo-emma-drag.webp' className={styles.photoEmma} drag dragConstraints={scrollRef} />
          <motion.img src='../images/darpa-tag-back.webp' className={styles.darpaTagBack} drag dragConstraints={scrollRef} />
        </div>
        {!isMobile && <div ref={scrollIntoRef} className={styles.scrollInto}></div>}
        {!isMobile && <div ref={bottomRef} className={styles.bottom}></div>}
      </div>
    </motion.div>
    </AnimatePresence>
  )
}
