import { useScramble } from 'use-scramble'
import styles from '../styles/Challenge.module.css'
import { AnimatePresence, easeInOut, easeOut, motion } from 'framer-motion'
import { useEffect } from 'react';

interface ChallengeProps {
  showVision: boolean
  playInitialTransition: boolean
}

export default function Challenge({ showVision, playInitialTransition } : ChallengeProps) {
  const { ref: challengeRef, replay: challengeReplay } = useScramble({ text: "THE CHALLENGE", overdrive: false, speed: 0.7, tick: 1, step: 1, scramble: 3, seed: 0, chance: 1, range: [97, 122], overflow: true, playOnMount: true })
  const { ref: visionRef, replay: visionReplay } = useScramble({ text: "OUR VISION", overdrive: false, speed: 0.7, tick: 1, step: 1, scramble: 3, seed: 0, chance: 1, range: [97, 122], overflow: true, playOnMount: true })

  const textVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 0.4, ease: easeOut } },
    exit: { opacity: 0, transition: { duration: 0.4, ease: easeOut } }
  };

  useEffect(() => {
    if (showVision) {
      visionReplay()
    } else {
      challengeReplay()
    }
  }, [showVision])

  return (
    <div className={styles.container}>
        <motion.div
          className={showVision ? styles.visionContainer : styles.challengeContainer}
          key="context-outer"
          layout
          layoutId="context-outer"
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: showVision ? 317 : 252, transition: { delay: 0, duration: 0.4, ease: easeOut } }}
        >
          <motion.div
            className={styles.contextInnerBorder}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0, duration: 1, ease: easeInOut } }}
            key="context-inner"
            layout
            layoutId="context-inner"
          >
            <AnimatePresence mode='wait'>
            {showVision ?
            <motion.div
              key="vision-title"
              variants={textVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <p className={styles.visionTitle} ref={visionRef} onMouseEnter={visionReplay}>Our Vision</p>
              <motion.p
                className={styles.text}
                variants={textVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                key="vision-title-1"
              >The BiTS Accelerator was founded to fill <span className={styles.em}>a critical gap in the research ecosystem.</span>
              </motion.p>
              <motion.p
                className={styles.subtext}
                variants={textVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                key="vision-title-2"
              >Many groundbreaking technologies, from mRNA vaccines to the internet, began with research programs too ambitious or interdisciplinary for traditional academic and corporate environments.
              </motion.p>
            </motion.div>
              :
            <motion.div
              key="challenge-title"
              variants={textVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <p className={styles.challengeTitle} ref={challengeRef} onMouseEnter={challengeReplay}>The Challenge</p>
              <motion.p
                className={styles.text}
                variants={textVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                key="challenge-title-1"
              >Some of the most significant advancements in science require long-term, coordinated research efforts that don't fit within academia or private sector timelines.
              </motion.p>
            </motion.div>
            }
            </AnimatePresence>
          </motion.div>
        </motion.div>
        <motion.div
          className={styles.connector}
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: showVision ? 20 : 50, opacity: 1, transition: { delay: playInitialTransition ? 1.2 : 0, duration: 0.4, ease: easeOut } }}
          key="connector"
          layout
          layoutId="connector"
        />
       <motion.div
        className={styles.descContainer}
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: 200, transition: { delay: playInitialTransition ? 1.4 : 0, duration: 1, ease: 'easeInOut' } }}
        exit={{ opacity: 0 }}
        key="desc"
      >
          <motion.div
            className={styles.descInnerBorder}
            variants={textVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            key="desc-inner"
          >
            <AnimatePresence mode='wait'>
            {showVision ? 
              <motion.p
                className={styles.text}
                key="vision-desc"
                variants={textVariants}
                initial="initial"
                animate="animate"
                exit="exit"
              >BiTS was created to bridge this gap by identifying and supporting the <span className={styles.em}>next generation of science leaders.</span>
              </motion.p>
              :
              <motion.p
                className={styles.text}
                key="challenge-desc"
                variants={textVariants}
                initial="initial"
                animate="animate"
                exit="exit"
              >At BiTS, we've recognized that these bold scientific ideas often fail to find the support they need, especially in the early stages of development. <span className={styles.em}>That's where we come in.</span>
              </motion.p>
            }
            </AnimatePresence>
          </motion.div>
        </motion.div>
    </div>
  )
}
