import styles from "../styles/Nav.module.css";
import ChevronForward from "../icons/ChevronForward";
import { useState } from 'react';
import { useScramble } from "use-scramble";
import { sectionNames, navIndexes } from "../utils/constants";
import { AnimatePresence, motion } from 'framer-motion';

interface NavProps {
    isMenuOpen: boolean;
    scrollRef: React.RefObject<HTMLDivElement>;
    height: number
}

export default function Nav({ isMenuOpen, scrollRef, height } : NavProps) {
    const [selectedSection, setSelectedSection] = useState(0);

    const { ref: homeRef, replay: homeReplay } = useScramble({ text: "HOME", overdrive: false, speed: 1, tick: 1, step: 1, scramble: 2, seed: 0, chance: 1, range: [97, 122], overflow: false, playOnMount: false })
    const { ref: aboutRef, replay: aboutReplay } = useScramble({ text: "ABOUT & HISTORY", overdrive: false, speed: 1, tick: 1, step: 1, scramble: 2, seed: 0, chance: 1, range: [97, 122], overflow: false, playOnMount: false })
    const { ref: teamRef, replay: teamReplay } = useScramble({ text: "TEAM & FELLOWS", overdrive: false, speed: 1, tick: 1, step: 1, scramble: 2, seed: 0, chance: 1, range: [97, 122], overflow: false, playOnMount: false })
    const { ref: programRef, replay: programReplay } = useScramble({ text: "PROGRAMS", overdrive: false, speed: 1, tick: 1, step: 1, scramble: 2, seed: 0, chance: 1, range: [97, 122], overflow: false, playOnMount: false })

    const sectionRefs = [homeRef, aboutRef, teamRef, programRef]
    const sectionAnimations = [homeReplay, aboutReplay, teamReplay, programReplay]

    const handleGoto = (i: number) => {
        setSelectedSection(i);
        sectionAnimations[i]();
        if (scrollRef.current) {
            scrollRef.current.scrollTo({
                left: 0,
                top: Math.max((navIndexes[i] * height), 0),
                behavior: 'instant'
            })
        }
    }

    return (
        <AnimatePresence initial={false}>
        {
            (!isMenuOpen) &&
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1, transition: {delay: 0.3, duration: 0}}}
                className={styles.container}
            >
                {sectionNames.map((name, i) => 
                    <div key={i} className={selectedSection === i ? styles.selectedSection : styles.section} onClick={_ => handleGoto(i)}>
                        <p ref={sectionRefs[i]} className={styles.title}>{name}</p>
                        <div className={styles.goto}>
                            <ChevronForward/>
                        </div>
                    </div>
                )}
            </motion.div>
        }
        </AnimatePresence>
    )
}