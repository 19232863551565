import { useEffect, useState } from 'react';
import About from './components/About';
import Grain from './components/Grain'
import Header from './components/Header'
import Intro from './components/Intro';
import LearnMore from './components/LearnMore';
import Menu from './components/Menu';
import Programs from './components/Programs';
import Roots from './components/Roots';
import Team from './components/Team';
import styles from './styles/Mobile.module.css'
import PixelTransition from './components/PixelTransition';
import ChallengeVision from './components/ChallengeVision';
import { AnimatePresence, motion, easeOut } from 'framer-motion';
import Footer from './components/Footer';
import Home from './components/Home';

export const mobileVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.4, ease: easeOut } },
  exit: { opacity: 0, transition: { duration: 0.4, ease: easeOut } }
};

export default function Mobile() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [height, setHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <AnimatePresence>
      <motion.div
        className={styles.container}
        key="mobile-wrapper"
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
        transition={{duration: 0.5, ease: easeOut}}
      >
        <Grain/>
        <Header setIsMenuOpen={setIsMenuOpen} />
        <div className={styles.sections}>
          <AnimatePresence>
            <div id="home"></div>
            <motion.div
              className={styles.fadeInWrapper}
              variants={mobileVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              key="1"
            >
              <Home isMobile={true} />
            </motion.div>
            <motion.div
              className={styles.fadeInWrapper}
              variants={mobileVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              key="1"
            >
              <Intro isMobile={true} />
            </motion.div>
            <div id="about"></div>
            <motion.div
              className={styles.fadeInWrapper}
              variants={mobileVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              key="2"
            >
              <About isMobile={true} />
            </motion.div>
            <motion.div
              className={styles.fadeInWrapper}
              variants={mobileVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              key="3"
            >
              <ChallengeVision />
            </motion.div>
            <motion.div
              className={styles.fadeInWrapper}
              variants={mobileVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              key="4"
            >
              <Roots parentScrollRef={null} isMobile={true} />
            </motion.div>
            <div id="team"></div>
            <motion.div
              className={styles.fadeInWrapper}
              variants={mobileVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              key="5"
            >
              <Team parentScrollRef={null} isMobile={true} />
            </motion.div>
            <div id="programs"></div>
            <motion.div
              className={styles.fadeInWrapper}
              variants={mobileVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              key="6"
            >
              <Programs parentScrollRef={null} isMobile={true} />
            </motion.div>
            <motion.div
              className={styles.fadeInWrapper}
              variants={mobileVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              key="7"
            >
              <LearnMore isMobile={true} /> 
            </motion.div>
            <Footer />
            <Menu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} scrollRef={null} height={height} isMobile={true} />
            <PixelTransition isMenuOpen={isMenuOpen}/>
          </AnimatePresence>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}
