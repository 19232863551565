import type { SVGProps } from "react";

const ChevronForward = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        {...props}
    >
        <path d="M9.82568 6.82715C9.82129 6.67334 9.76416 6.5415 9.64551 6.42285L6.22656 3.07861C6.12549 2.98193 6.00684 2.9292 5.86182 2.9292C5.56738 2.9292 5.33887 3.15771 5.33887 3.45215C5.33887 3.59277 5.396 3.72461 5.49707 3.82568L8.57324 6.82715L5.49707 9.82861C5.396 9.92969 5.33887 10.0571 5.33887 10.2021C5.33887 10.4966 5.56738 10.7251 5.86182 10.7251C6.00244 10.7251 6.12549 10.6724 6.22656 10.5757L9.64551 7.22705C9.76855 7.11279 9.82568 6.98096 9.82568 6.82715Z" fill="white"/>
    </svg>
);
export default ChevronForward;