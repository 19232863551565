import ChevronForward from '../icons/ChevronForward';
import styles from '../styles/Home.module.css';
import glyph from '../assets/glyph.svg';
import { useScramble } from 'use-scramble';
import ChevronDown from '../icons/ChevronDown';
import { AnimatePresence, motion } from 'framer-motion';
import { fadeVariants } from '../utils/constants';

interface HomeProps {
    isMobile: boolean
}

export default function Home({ isMobile } : HomeProps) {
    const { ref: learnMoreRef, replay: learnMoreReplay } = useScramble({ text: "LEARN MORE", overdrive: false, speed: 1, tick: 1, step: 1, scramble: 2, seed: 0, chance: 1, range: [97, 122], overflow: false, playOnMount: false })

    return (
        <AnimatePresence>
        <motion.div
            className={isMobile ? styles.mobileContainer : styles.container}
            variants={fadeVariants}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <div className={styles.heroWrapper}>
                <div className={styles.hero}>
                    <img className={styles.glyph} src={glyph}/>
                    <div className={styles.tagline}>
                        We're building something that's <span className={styles.bigger}>bigger</span> than the sum of its <span className={styles.bits}>"bits"</span>
                    </div>
                    <div className={styles.moreWrapper} onMouseEnter={() => learnMoreReplay() }>
                        <div className={styles.more}>
                            <p ref={learnMoreRef}>Learn more</p>
                            <div className={styles.goto}>
                                <div className={styles.arrow1}></div>
                                <div className={styles.arrow2}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
        </AnimatePresence>
    )
}