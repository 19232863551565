import { useRef } from 'react';
import styles from '../styles/Docs.module.css'
import { easeOut, motion } from 'framer-motion'

interface DocsProps {
  isMobile: boolean
}

const docVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.4, ease: easeOut } },
  exit: { opacity: 0, transition: { duration: 0.4, ease: easeOut } }
};

export default function Docs({ isMobile }: DocsProps) {
  const constraintsRef = useRef(null)

  return (
    <div className={isMobile ? styles.docsMobile : styles.docs}>
      <div ref={constraintsRef} className={isMobile ? styles.mobileConstraints : styles.constraints}></div>
      <motion.img
        src='../images/letter-left-drag.webp'
        className={styles.letterLeft}
        variants={docVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        drag
        dragConstraints={constraintsRef}
      />
      <motion.img
        src='../images/letter-right-drag.webp'
        className={styles.letterRight}
        variants={docVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        drag
        dragConstraints={constraintsRef}
      />
      <motion.img
        src='../images/darpa-tag-drag.webp'
        className={styles.darpaTag}
        variants={docVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        drag
        dragConstraints={constraintsRef}
      />
      <motion.img
        src='../images/photo-muniza-drag.webp'
        className={styles.photoMuniza}
        variants={docVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        drag
        dragConstraints={constraintsRef}
      />
      <motion.img
        src='../images/photo-emma-drag.webp'
        className={styles.photoEmma}
        variants={docVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        drag
        dragConstraints={constraintsRef}
      />
      <motion.img
        src='../images/rocket.webp'
        className={styles.rocket}
        variants={docVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        drag
        dragConstraints={constraintsRef}
      />
      <motion.img
        src='../images/darpa-tag-back.webp'
        className={styles.darpaTagBack}
        variants={docVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        drag
        dragConstraints={constraintsRef}
      />
    </div>
  )
}
