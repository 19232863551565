import { useScramble } from 'use-scramble'
import styles from '../styles/ChallengeVision.module.css'

export default function ChallengeVision() {
  const { ref: challengeRef, replay: challengeReplay } = useScramble({ text: "THE CHALLENGE", overdrive: false, speed: 0.7, tick: 1, step: 1, scramble: 3, seed: 0, chance: 1, range: [97, 122], overflow: true, playOnMount: true })
  const { ref: visionRef, replay: visionReplay } = useScramble({ text: "OUR VISION", overdrive: false, speed: 0.7, tick: 1, step: 1, scramble: 3, seed: 0, chance: 1, range: [97, 122], overflow: true, playOnMount: true })

  return (
    <div className={styles.container}>
      <div className={styles.challengeContainer}>
        <div className={styles.contextInnerBorder}>
          <p className={styles.challengeTitle} ref={challengeRef}>The Challenge</p>
          <p className={styles.text}>Some of the most significant advancements in science require long-term, coordinated research efforts that don't fit within academia or private sector timelines.</p>
        </div>
      </div>
      <div className={styles.connector}/>
      <div className={styles.descContainer}>
        <div className={styles.descInnerBorder}>
          <p className={styles.text}>At BiTS, we've recognized that these bold scientific ideas often fail to find the support they need, especially in the early stages of development. <span className={styles.em}>That's where we come in.</span></p>
        </div>
      </div>
      <div className={styles.visionContainer}>
        <div className={styles.contextInnerBorder}>
          <p className={styles.visionTitle} ref={visionRef} onMouseEnter={visionReplay}>Our Vision</p>
          <p className={styles.text}>The BiTS Accelerator was founded to fill <span className={styles.em}>a critical gap in the research ecosystem.</span></p>
          <p className={styles.subtext}>Many groundbreaking technologies, from mRNA vaccines to the internet, began with research programs too ambitious or interdisciplinary for traditional academic and corporate environments.</p>
        </div>
      </div>
      <div className={styles.connector}/>
      <div className={styles.descContainer}>
        <div className={styles.descInnerBorder}>
          <p className={styles.text}>BiTS was created to bridge this gap by identifying and supporting the <span className={styles.em}>next generation of science leaders.</span></p>
        </div>
      </div>
    </div>
  )
}
