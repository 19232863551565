import styles from '../styles/Footer.module.css'

export default function Footer() {
  return (
    <div className={styles.container}>
        <p className={styles.name}>© bits -- 2024</p>
        <img className={styles.iconMark} src='../images/icon-mark.svg' />
    </div>
  )
}
