import { AnimatePresence, motion } from 'framer-motion';
import styles from '../styles/About.module.css'
import Docs from './Docs';
import { fadeVariants } from '../utils/constants';

interface AboutProps {
  isMobile: boolean
}

export default function About({ isMobile } : AboutProps) {
  return (
  <AnimatePresence>
    <motion.div
      className={isMobile ? styles.mobileContainer : styles.container}
      variants={fadeVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className={styles.innerBorder}>
        <p className={styles.text}>Our 15-week accelerator identifies, mentors, and trains the most ambitious entrepreneurs and scientists to lead large-scale research programs, harnessing new philanthropic and public funding opportunities to create real-world impact.</p>
      </div>
      {isMobile &&
        <Docs isMobile={isMobile} />
      }
    </motion.div>
  </AnimatePresence>
  )
}