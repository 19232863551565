import { fadeVariants } from '../utils/constants';
import styles from '../styles/Intro.module.css';
import { AnimatePresence, easeOut, motion } from 'framer-motion'

interface IntroProps {
    isMobile: boolean
}
  
export default function Intro({ isMobile }: IntroProps) {
  return (
    <AnimatePresence>
    <motion.div
        className={isMobile ? styles.mobileContainer : styles.container}
        variants={fadeVariants}
        initial="initial"
        animate="animate"
        exit="exit"
    >
        <motion.img
            className={styles.blueOverlay}
            src='../images/blue-overlay.svg'
            variants={fadeVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            key="blue-overlay"
        />
        <div className={styles.dottedWrapper}>
            <div className={styles.intro}>
                <div className={styles.title}>
                    <div className={styles.top}>
                        <p>Introduction</p>
                        <p>01</p>
                    </div>
                    <div className={styles.divider}></div>
                </div>
                <div className={styles.text}>
                    <p className={styles.body}>A number of transformative advancements in science and technology - such as the creation of mRNA vaccines, the field of molecular biology, and even the internet - have been the result of ambitious coordinated research programs.</p>
                    <p className={styles.body}>Coordinated research programs focus on challenges that are too large, applied, or interdisciplinary, to fit within academia and are either too early or not profitable enough for corporate or venture capital. This valley of death often requires patient funding of governments and philanthropies to surmount.</p>

                </div>
            </div>
            <div className={styles.panel}>
                <div className={styles.mission}>
                    <p className={styles.heading}>We exist to support and elevate the brightest minds in science and technology </p>
                    <p className={styles.body}>At BiTS, we’re empowering the next generation of scientific visionaries to develop bold, transformative ideas that could reshape the future.</p>
                </div>
                <div className={styles.tagline}>
                    <p className={styles.heading}>all of the BiTS are coming <span className={styles.italic}>together</span></p>
                </div>
            </div>
        </div>
    </motion.div>
    </AnimatePresence>
  )
}
