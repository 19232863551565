import { useState } from 'react';
import styles from '../styles/Menu.module.css'
import { useScramble } from "use-scramble";
import { sectionNames, navIndexes } from "../utils/constants";
import ChevronForward from '../icons/ChevronForward';
import { AnimatePresence, easeOut, motion } from 'framer-motion';

interface MenuProps {
    isMenuOpen: boolean;
    setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
    scrollRef: React.RefObject<HTMLDivElement> | null;
    height: number;
    isMobile: boolean
}

const selectionVariants = (i : number) => {
    return {
        initial: { opacity: 0, y: -20 },
        animate: { opacity: 1, y: 0, transition: { duration: 0.08, ease: easeOut, delay: 0.25 + (0.05 * i) } },
        exit: { opacity: 0, y: -20, transition: { duration: 0.08, ease: easeOut, delay: 0.35 - (0.08 * i) } }
    };
}

const contactVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 0.4, ease: easeOut, delay: 0.5 } },
    exit: { opacity: 0, transition: { duration: 0.15, ease: easeOut, delay: 0.1 } }
};

export default function Menu({ isMenuOpen, setIsMenuOpen, scrollRef, height, isMobile } : MenuProps ) {
    const [selectedSection, setSelectedSection] = useState(0);

    const { ref: homeRef, replay: homeReplay } = useScramble({ text: "HOME", overdrive: false, speed: 1, tick: 1, step: 1, scramble: 2, seed: 0, chance: 1, range: [97, 122], overflow: false, playOnMount: false })
    const { ref: aboutRef, replay: aboutReplay } = useScramble({ text: "ABOUT & HISTORY", overdrive: false, speed: 1, tick: 1, step: 1, scramble: 2, seed: 0, chance: 1, range: [97, 122], overflow: false, playOnMount: false })
    const { ref: teamRef, replay: teamReplay } = useScramble({ text: "TEAM & FELLOWS", overdrive: false, speed: 1, tick: 1, step: 1, scramble: 2, seed: 0, chance: 1, range: [97, 122], overflow: false, playOnMount: false })
    const { ref: programRef, replay: programReplay } = useScramble({ text: "PROGRAMS", overdrive: false, speed: 1, tick: 1, step: 1, scramble: 2, seed: 0, chance: 1, range: [97, 122], overflow: false, playOnMount: false })

    const sectionRefs = [homeRef, aboutRef, teamRef, programRef]
    const sectionAnimations = [homeReplay, aboutReplay, teamReplay, programReplay]
    const sectionIds = ["#home", "#about", "#team", "#programs"]

    const handleGoto = (i: number) => {
        setSelectedSection(i);
        sectionAnimations[i]();
        if (!isMobile) {
            if (scrollRef && scrollRef.current) {
                scrollRef.current.scrollTo({
                    left: 0,
                    top: Math.max((navIndexes[i] * height), 0),
                    behavior: 'instant'
                })
            }
        }
        setTimeout(() => {
            setIsMenuOpen(false);
        }, 500);
    }

    return (
        <AnimatePresence>
            {isMenuOpen &&
            <div className={styles.container}>
                <div className={styles.selectionWrapper}>
                    <div className={styles.selection}>
                        <motion.p
                            className={styles.title}
                            variants={isMobile ? selectionVariants(sectionIds.length - 1) : selectionVariants(0)}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                        >
                            Navigate...
                        </motion.p>
                        <div className={styles.sections}>
                        {sectionNames.map((name, i) => 
                            <motion.a
                                key={i}
                                className={selectedSection === i ? styles.selectedSection : styles.section}
                                onClick={_ => handleGoto(i)}
                                variants={selectionVariants(i + 1)}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                href={isMobile ? sectionIds[i] : undefined}
                            >
                                <div className={styles.sectionContainer}>
                                    <p ref={sectionRefs[i]} className={styles.sectionTitle}>{name}</p>
                                    <div className={styles.goto}>
                                        <ChevronForward/>
                                    </div>
                                </div>
                            </motion.a>
                        )}
                        </div>
                    </div>
                    <motion.div
                        className={styles.contact}
                        variants={contactVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                    >
                        <p className={styles.title}>Contact Us</p>
                        <p className={styles.email}>hello@bigiftrue.org</p>
                        <p className={styles.year}>--2024</p>
                    </motion.div>
                </div>
            </div>
            }
        </AnimatePresence>
    )
}