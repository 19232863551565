import { useEffect, useRef, useState } from 'react'
import ChevronForward from '../icons/ChevronForward'
import styles from '../styles/Programs.module.css'
import { useInView } from 'react-intersection-observer'
import { useScramble } from 'use-scramble'
import { AnimatePresence, motion } from 'framer-motion'
import { fadeVariants } from '../utils/constants'

interface ProgramsProps {
  parentScrollRef: React.RefObject<HTMLDivElement> | null
  isMobile: boolean
}

export default function Programs({ parentScrollRef, isMobile } : ProgramsProps) {
  const { ref: contactRef, replay: contactReplay } = useScramble({ text: "CONTACT US", overdrive: false, speed: 0.8, tick: 1, step: 1, scramble: 3, seed: 0, chance: 10, range: [97, 122], overflow: true, playOnMount: false })

  const scrollRef = useRef<HTMLDivElement | null>(null)
  const scrollIntoRef = useRef<HTMLDivElement | null>(null)
  const { ref: topRef, inView: topInView, entry: topEntry } = useInView({
    threshold: 0,
  });
  const { ref: bottomRef, inView: bottomInView, entry: bottomEntry } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (isMobile) return

    if (scrollIntoRef.current) {
      scrollIntoRef.current.scrollIntoView({block: "start"})
    }
  }, [scrollRef])

  const handleScroll = (e: React.WheelEvent<HTMLDivElement>) => {
    if (parentScrollRef && parentScrollRef.current) {
      parentScrollRef.current.style.pointerEvents = 'all'
      parentScrollRef.current.style.overflowY = 'scroll'
      if (topInView) {
        parentScrollRef.current.scrollTo({
          left: 0,
          top: 6 * window.innerHeight,
          behavior: 'instant'
        })
      }
      else if (bottomInView) {
        parentScrollRef.current.scrollTo({
          left: 0,
          top: 8 * window.innerHeight,
          behavior: 'instant'
        })
      }
    }
  };

  return (
    <AnimatePresence>
    <motion.div
      className={isMobile ? styles.mobileContainer : styles.scrollableContainer}
      ref={scrollRef}
      onWheel={isMobile ? () => {} : handleScroll}
      variants={fadeVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {!isMobile && <div ref={topRef} className={styles.top}></div>}
      <img className={styles.blueOverlay} src='../images/blue-overlay.svg' />
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.intro}>
              <h1 className={styles.title}>Programs</h1>
              <a href='mailto:hello@bigiftrue.org' onMouseEnter={contactReplay} className={styles.contactContainer}>
                <p className={styles.contact} ref={contactRef}>Contact Us</p>
                <div className={styles.goto}>
                  <ChevronForward/>
                </div>
              </a>
            </div>
            <div className={styles.divider}></div>
            <p className={styles.body}>BiTS identifies and nurtures scientists with groundbreaking ideas. Our 15-week accelerator offers a comprehensive, hands-on program designed to equip fellows with the tools, skills, and connections they need to launch high-impact research initiatives that attract major funding and create real impact. </p>
          </div>
        <div className={styles.main}>
          <div className={styles.innerBorder}>
            <img className={styles.ditherFade} src='../images/dither-fade-mini.webp' />
            <div className={styles.sections}>
              <div className={styles.section}>
                <h3 className={styles.heading}>Program Design and Development:</h3>
                <p className={styles.body}>Fellows learn how to develop large-scale, translational research programs that can secure substantial philanthropic and public funding.</p>
              </div>
              <div className={styles.section}>
                <h3 className={styles.heading}>Field Exploration and Idea Refinement:</h3>
                <p className={styles.body}>Through exploration and mapping, fellows identify current research gaps, engage with experts in their fields, and refine their program ideas to maximize impact.</p>
              </div>
              <div className={styles.section}>
                <h3 className={styles.heading}>Practical Program Execution:</h3>
                <p className={styles.body}>Fellows gain hands-on experience in program execution, learning about project management, budgeting, risk mitigation, and how to engineer successful programs.</p>
              </div>
              <div className={styles.section}>
                <h3 className={styles.heading}>Technical Transition:</h3>
                <p className={styles.body}>Our program places a strong emphasis on transitioning technologies into real-world applications. Fellows work with potential funders and transition partners to ensure their research can be scaled and implemented.</p>
              </div>
              <div className={styles.section}>
                <h3 className={styles.heading}>Networking and Mentorship:</h3>
                <p className={styles.body}>Fellows are connected to experienced Program Managers (PMs) and a diverse network of performers, peers, mentors, and transition partners to ensure their programs' success.</p>
              </div>
            </div>
          </div>
        </div>
        {!isMobile && <div ref={scrollIntoRef} className={styles.scrollInto}></div>}
        {!isMobile && <div ref={bottomRef} className={styles.bottom}></div>}
      </div>
    </motion.div>
    </AnimatePresence>
  )
}
