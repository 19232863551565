import { useScramble } from 'use-scramble'
import ChevronForward from '../icons/ChevronForward'
import styles from '../styles/LearnMore.module.css'
import { AnimatePresence, motion } from 'framer-motion'
import Footer from './Footer'
import { fadeVariants } from '../utils/constants'

interface LearnMoreProps {
  isMobile: boolean
}

export default function LearnMore({ isMobile } : LearnMoreProps) {
  const { ref: emailRef, replay: emailReplay } = useScramble({ text: "EMAIL US", overdrive: false, speed: 0.7, tick: 1, step: 1, scramble: 3, seed: 0, chance: 1, range: [97, 122], overflow: true, playOnMount: false })

  return (
    <AnimatePresence>
    <motion.div
      className={isMobile ? styles.invisible : styles.wrapper}
      variants={fadeVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className={isMobile ? styles.mobileContainer : styles.container}>
        <div className={styles.gradient}></div>
        <div className={styles.titleWrapper}>
        <h1 className={styles.title}>Interested in Learning More?</h1>
        <div className={styles.main}>
            <motion.img
              className={styles.moon}
              src='../images/moon.webp'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            />
            <div className={styles.contact}>
              <h3 className={styles.heading}>We'd love to hear from you.</h3>
              <p className={styles.body}>Whether you're a potential fellow, a funder, or simply curious about our work, we're here to answer your questions.</p>
              <p className={styles.body}>For inquiries, reach out to: <span className={styles.emailAddress}>hello@bigiftrue.org</span></p>
              <div className={styles.emailWrapper}>
                <a href='mailto:hello@bigiftrue.org' className={styles.emailContainer} onMouseEnter={emailReplay}>
                <p className={styles.email} ref={emailRef}>Email Us</p>
                <div className={styles.goto}>
                  <ChevronForward/>
                </div>
                </a>
              </div>
          </div>
          </div>
        </div>
        {!isMobile && <Footer />}
      </div>
    </motion.div>
    </AnimatePresence>

  )
}
