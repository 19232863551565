import { useState, useEffect } from "react";
import styles from './styles/App.module.css'
import { Analytics } from "@vercel/analytics/react"
import bcrypt from 'bcryptjs';
import { isMobile } from "react-device-detect";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import { AnimatePresence, easeOut, motion } from "framer-motion";
import Docs from "./components/Docs";

function App() {
  const [input, setInput] = useState('');
  const [status, setStatus] = useState('idle') // `success` `failure` `idle`
  const hashedPassword = process.env.REACT_APP_PASSWORD_HASH;
  const [width, setWidth] = useState(window.innerWidth)
  
  const handleSubmit = () => {
    bcrypt.compare(input, hashedPassword, (err : any, result : any) => {
      if (result) {
        setStatus('success');
      } else {
        setStatus('failure');
      }
    });
  };

  useEffect(() => {
    if (status === 'success') {
      document.body.style.overflow = '';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [status])

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
    {(status === 'success') ? (
      (isMobile || width < 700)
        ?
        <Mobile/>
        :
        <Desktop/>
      ) : (
        <div className={styles.container}>
          <div className={styles.passwordContainer}>
            <p className={styles.title}>Big if True Science</p>
            <div className={styles.infoWrapper}>
              <img className={styles.lock} src='../images/lock.svg'/>
              <p className={styles.info}>Protected content</p>
            </div>
            <div className={status === 'failure' ? styles.inputContainerFailure : styles.inputContainer}>
              <input
                className={styles.passwordInput}
                type="password" 
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit() }}
                placeholder="Enter password"
                autoFocus
              />
              <button onClick={handleSubmit} className={styles.submit}>→</button>
            </div>
          </div>
        </div>
      )
    }
    <Analytics/>
    </>
  )
}

export default App;
